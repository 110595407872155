import React, { useEffect, useState } from "react";

import Axios from "../../services/Api";
import app_params from "../../config/params";

import styles from "./AcquisitionScreen.module.css";

const goToRecord = (id) =>
  (window.location.href = `${app_params.basepath}/record/${id}`);

const AcquisitionScreen = (props) => {
  const [acquisitions, setAcquisitions] = useState({ isSet: false });

  useEffect(() => {
    Axios.GetAcquisitions()
      .then((res) => res.data)
      .then((data) => {
        if (data?.success === "true") {
          console.log(data);
          setAcquisitions({
            isSet: true,
            classifications: data.classifications,
          });
        } else {
          setAcquisitions({ isSet: false });
          window.location.replace(`${app_params.basepath}/404`);
        }
      })
      .catch((e) => {
        console.log(e);
        setAcquisitions({ isSet: false });
      });
  }, []);

  return (
    <section className="container">
      {acquisitions.isSet && (
        <div className="row">
          <div className="col-2 d-none d-lg-block">
            <div className="my-3 p-3 sticky-top">
              {acquisitions.classifications.map((classification) => (
                <div className="mb-2" key={classification.id}>
                  <a
                    href={`#${classification.id}`}
                    className="fw-bold text-decoration-none"
                  >
                    <i className="fa fa-angle-right"></i> {classification.name}
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="col-12 col-lg-10">
            {acquisitions.classifications.map((classification) => (
              <div
                className="row bg-white my-2 my-lg-3 p-3 shadow-sm rounded"
                id={classification.id}
                key={classification.id}
              >
                <h5>{classification.name}</h5>
                <div className="row p-2">
                  {Array.isArray(classification?.records) && (
                    <React.Fragment>
                      {classification?.records.map((item) => (
                        <figure
                          className={`figure ${styles.cover_card} p-2 col-6 col-lg-4 col-xl-2`}
                          key={item.id}
                          onClick={() => goToRecord(item.id)}
                        >
                          <img
                            type="button"
                            src={item?.thumb}
                            className={`figure-img img-fluid rounded border border-1 ${styles.cover_thumb}`}
                            alt={item.title}
                          />
                          <figcaption
                            className={`figure-caption text-center text-truncate ${styles.cover_caption}`}
                          >
                            {item.title}
                          </figcaption>
                          {item?.author !== "null" && (
                            <figcaption
                              className={`figure-caption text-center text-truncate ${styles.cover_caption}`}
                            >
                              {item?.author}
                            </figcaption>
                          )}
                        </figure>
                      ))}
                    </React.Fragment>
                  )}
                  {!Array.isArray(classification?.records) && (
                    <React.Fragment>
                      <figure
                          className={`figure ${styles.cover_card} p-2 col-6 col-lg-4 col-xl-2`}
                          onClick={() => goToRecord(classification?.records.id)}
                        >
                          <img
                            type="button"
                            src={classification?.records?.thumb}
                            className={`figure-img img-fluid rounded border border-1 ${styles.cover_thumb}`}
                            alt={classification?.records.title}
                          />
                          <figcaption
                            className={`figure-caption text-center text-truncate ${styles.cover_caption}`}
                          >
                            {classification?.records.title}
                          </figcaption>
                          {classification?.records?.author !== "null" && (
                            <figcaption
                              className={`figure-caption text-center text-truncate ${styles.cover_caption}`}
                            >
                              {classification?.records?.author}
                            </figcaption>
                          )}
                        </figure>
                    </React.Fragment>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  );
};

export default AcquisitionScreen;
