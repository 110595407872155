export const status_dictionary = {
	C: {
		displayText: 'En Catalogación',
		cssClass: 'bg-info',
	},
	D: {
		displayText: 'Disponible',
		cssClass: 'bg-success',
	},
	E: {
		displayText: 'Encuadernacion',
		cssClass: 'bg-info',
	},
	I: {
		displayText: 'Pedido',
		cssClass: 'bg-danger',
	},
	L: {
		displayText: 'En Línea',
		cssClass: 'bg-info',
	},
	P: {
		displayText: 'Prestado',
		cssClass: 'bg-warning',
	},
	R: {
		displayText: 'Reservado',
		cssClass: 'bg-info',
	},
	S: {
		displayText: 'Consulta en Sala',
		cssClass: 'bg-info',
	},
	T: {
		displayText: 'Descarte',
		cssClass: 'bg-danger',
	},
	U: {
		displayText: 'Desubicado',
		cssClass: 'bg-danger',
	},
	X: {
		displayText: 'Extraviado',
		cssClass: 'bg-danger',
	},
};
