import React from 'react';

import app_params from "../../config/params";
import styles from './Carousel.module.css';

const CoverCarousel = (props) => {
	const goToRecord = (id) => (window.location.href = `${app_params.basepath}/record/${id}`);

	return (
		<div className='bg-white mx-0 mx-md-3 my-3 p-3 shadow-sm rounded'>
			<h5>{props.title}</h5>
			<div
				className={`d-flex flex-row align-items-start overflow-scroll ${styles.mobile_drag}`}>
				{props.items.map((item) => (
					<figure
						className={`figure ${styles.cover_card} p-2`}
						key={item.num_titulo}
						onClick={() => goToRecord(item.num_titulo)}>
						<img
							type='button'
							src={item?.portada}
							className={`figure-img img-fluid rounded border border-1 ${styles.cover_thumb}`}
							alt={item.titulo}
						/>
						<figcaption className={`figure-caption text-center text-truncate ${styles.cover_caption}`}>
							{item.titulo}
						</figcaption>
					</figure>
				))}
			</div>
			{props?.hasDetailPage && (
				<div className='text-center mt-3'><a href={`${app_params.basepath}/acquisitions`}>Ver todas las novedades</a></div>
			)}
		</div>
	);
};

export default CoverCarousel;
