import React, { useState } from "react";
import { useSelector } from "react-redux";

import styles from "./SearchResultItem.module.css";

import Axios from "../../services/Api";
import QuoteModal from "../QuoteModal/QuoteModal";
import app_params from "../../config/params";
import ShareModal from "../ShareModal/ShareModal";
import ApiURLs from "../../config/ApiURLs"

const recordDownload = async (record, file) => {
  const { data } = await Axios.DownloadHit({
    record: record,
  });

  window.open(file, "_blank");
};

const detailsHelper = {
  true: `Ver más <i class="fas fa-angle-down"></i>`,
  false: `Ver menos <i class="fas fa-angle-up"></i>`,
};

const SearchResultItem = (props) => {
  const {
    result: { result_header },
  } = useSelector((state) => state.searchResult);
  const [toggleDetails, setToggleDetails] = useState(true);

  const viewTypeBook = () => {
    if (props.item?.containericon) {
      return (
        <React.Fragment>
          <img alt="icon" src={`${ApiURLs.siteUrl}/library/skins/wx/${props.item.containericon}`} style={{height:"50px"}}></img>
          {props.item.containericon === 'ctTesis.png' && " Tesis"}
          {props.item.containericon === 'ctVolume.png' && " Libro"}
          {props.item.containericon === 'ctJoyas.png' && " Joyas Bibliogáficas"}
          {props.item.containericon === 'ctFolletos.png' && " Folleto"}
          {props.item.containericon === 'ctRevistas.png' && " Revista"}
          {props.item.containerdisp === 'ctOnline.png' && " Digital"}
        </React.Fragment>
      );
    }else {
      return (<div></div>)
    }
  };

  const colapseAuthors = () => {
    if (props.item?.authors) {
      if (Array.isArray(props.item?.authors)) {
        if (props.item?.authors.length > 3) {
          const primeros = props.item?.authors.slice(0, 3);
          const demas = props.item?.authors.slice(3, -1);
          return (
            <React.Fragment>
              <div>
                {primeros}
                <div
                  className="btn btn-light  btn-sm"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={"#col" + props.item?.id}
                  aria-expanded="false"
                  aria-controls={"col" + props.item?.id}
                  dangerouslySetInnerHTML={{
                    __html: detailsHelper[toggleDetails],
                  }}
                  onClick={() => setToggleDetails(!toggleDetails)}
                ></div>
                <div className="collapse" id={"col" + props.item?.id}>
                  {demas}
                </div>
              </div>
            </React.Fragment>
          );
        }
      }
      return (
        <React.Fragment>
          {props.item?.authors}
          <br />
        </React.Fragment>
      );
    }
    return <div></div>;
  };
  // const shareRecord = () => {
  //   //TODO: Modal to share in different social media pages
  //   navigator.clipboard.writeText(
  //     `${window.location.origin}${app_params.basepath}/record/${props.item?.id}?${encodeURI(
  //       result_header?.searchargs
  //     )}${encodeURI(props?.searchargs)}`
  //   );
  // };

  return (
    <div
      className="col-12 mb-2 p-2 bg-white shadow-sm rounded"
      key={props.item?.id}
    >
      <div className="row">
        <div className="col-4 col-lg-2">
          <div className="row">
            <div className="col-12">
            <a target="_blank" rel="noreferrer" href={props.item?.thumbnail?.toLowerCase() !== "null"
                    ? props?.item?.thumbnail
                    : ""}>
              <img
                src={
                  props.item?.thumbnail?.toLowerCase() !== "null"
                  ? props?.item?.thumbnail
                  : ""
                }
                className={`img-thumbnail rounded`}
                alt=""
                />
                </a>
            </div>
            <div className="col-12 d-flex d-lg-none flex-column justify-content-center mt-3">
              {/* <button
                className="btn btn-sm btn-outline-secondary col-12 mb-2"
                id={`shareRecordButton-${props?.item?.id}`}
                onClick={() => shareRecord()}
              >
                <span>
                  <i className="fas fa-share-alt"></i> Compartir
                </span>
              </button> */}
              <button
                className="btn btn-sm btn-outline-secondary col-12 mb-2"
                data-bs-toggle="modal"
                data-bs-target={`#shareModal-${props?.item?.id}`}
              >
                <span>
                  <i className="fas fa-share-alt"></i> Compartir
                </span>
              </button>
              <button
                className="btn btn-sm btn-outline-secondary col-12"
                data-bs-toggle="modal"
                onClick={(event) => event.preventDefault()}
                data-bs-target={`#quoteModal-${props?.item?.id}`}
              >
                <span>
                  <i className="fas fa-quote-left"></i> Referencia
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="col-8 col-lg-8 d-flex flex-column justify-content-between">
          <div className="row mb-2">
            <div className="col-12">
              <a
                href={`${app_params.basepath}/record/${props.item?.id}?${result_header?.searchargs}${props?.searchargs}`}
                className={`text-decoration-none ${styles.item_title}`}
              >
                {props.item?.title}
              </a>
              <br />
              {colapseAuthors()}
              {props.item?.editorial && (
                <React.Fragment>
                  {props.item?.editorial}
                  <br />
                </React.Fragment>
              )}
              {props.item?.classification && (
                <React.Fragment>
                  <b style={{ color: '#303030' }}>{props.item?.classification}</b>
                  <br />
                </React.Fragment>
              )}
              {props.item?.edicion && (
                <React.Fragment>
                  {props.item?.edicion}
                  <br />
                </React.Fragment>
              )}
              {props.item?.biblioteca && (
                <React.Fragment>
                  {props.item?.biblioteca}
                  <br />
                </React.Fragment>
              )}
            </div>
            <div className="col-12 col-lg-10">
              {props.item?.available === "true" && (
                <div>
                  <br />
                  <span className="badge roudend-pill bg-success">
                    <i className="fas fa-check"></i> Disponible
                  </span>
                </div>
              )}
              {props.item?.available === "false" && (
                <div>
                  <br />
                  <span className="badge roudend-pill bg-danger">
                    <i className="fas fa-times"></i> No disponible
                  </span>
                </div>
              )}
              {viewTypeBook()}
              <br />
              {props.item?.digital && (
                <button
                  className="btn btn-primary mt-2"
                  onClick={() =>
                    recordDownload(props.item?.id, props.item?.digital)
                  }
                >
                  <span className="me-2">
                    <i className="fas fa-globe-americas"></i>
                  </span>
                  En línea
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="col-2 d-none d-lg-flex flex-column justify-content-start">
          {/* <button
            className="btn btn-sm btn-outline-secondary col-12 mb-2"
            id={`shareRecordButtonResponsive-${props?.item?.id}`}
            onClick={() => shareRecord()}
          >
            <span>
              <i className="fas fa-share-alt"></i> Compartir
            </span>
          </button> */}
          <button
            className="btn btn-sm btn-outline-secondary col-12 mb-2"
            data-bs-toggle="modal"
            data-bs-target={`#shareModal-${props?.item?.id}`}
          >
            <span>
              <i className="fas fa-share-alt"></i> Compartir
            </span>
          </button>
          <button
            className="btn btn-sm btn-outline-secondary col-12 mb-2"
            onClick={(event) => event.preventDefault()}
            data-bs-toggle="modal"
            data-bs-target={`#quoteModal-${props?.item?.id}`}
          >
            <span>
              <i className="fas fa-quote-left"></i> Referencia
            </span>
          </button>
        </div>
      </div>
      {/* <Toast
        buttonID={`shareRecordButton-${props?.item?.id}`}
        toastID={`shareRecordToast-${props?.item?.id}`}
        variant="secondary"
      >
        Enlace de título copiado en el portapapeles
      </Toast>
      <Toast
        buttonID={`shareRecordButtonResponsive-${props?.item?.id}`}
        toastID={`shareRecordToast-${props?.item?.id}`}
        variant="secondary"
      >
        Enlace de título copiado en el portapapeles
      </Toast> */}
      <QuoteModal
        modalID={`quoteModal-${props?.item?.id}`}
        item={props?.item}
      />
      <ShareModal
        id={`shareModal-${props?.item?.id}`}
        url={`${window.location.origin}${app_params.basepath}/record/${
          props.item?.id
        }?${encodeURI(result_header?.searchargs)}${encodeURI(
          props?.searchargs
        )}`}
        quote={props.item?.title}
      />
    </div>
  );
};

export default SearchResultItem;
