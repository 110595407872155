import React from "react";

import app_params from "../../config/params";

const Navbar = (props) => {
  return (
    <header>
      <div class="container-fluid">
        <div class="row justify-content-cente">
          <nav className={`navbar navbar-expand-md navbar-dark theme-navbar`}>
            <div class="col-6 col-lg-6 lema">
              <div class="d-flex justify-content-cente">
              <a className="navbar-brand logo" href={`${app_params.basepath}`}>
                <img
                  src={`${app_params.basepath}/assets/logos/logo.png`}
                  alt="brand-logo"
                  className=" brand-logo lema mt-2"
                />
              </a>
                <img
                  src={`${app_params.basepath}/assets/logos/eslogan.png`}
                  alt="brand-logo"
                  className=" brand-logo lema hidden-mobile"
                />
              </div>
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
